<template>
  <div>
    <div class="wrapper face-b" v-if="isWeixin"></div>
    <div v-else class="wrapper face-a">
      <div style="height: 118px"></div>
      <p class="slogan">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </p>
      <button class="download-btn" @click="downloadPackage">
        <span>免费下载</span>
        <img src="../assets/download.svg" alt="" class="download-logo" />
      </button>
    </div>
  </div>
</template>

<script>
import { service } from "../utils/request";
import { Dialog } from "vant";
export default {
  data() {
    return {
      isIOS: window.navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      isWeixin:
        window.navigator.userAgent.toLowerCase().indexOf("micromessenger") !==
        -1,
    };
  },
  methods: {
    async downloadPackage() {
      let downloadUrl = "";
      const res = await service.get(
        "/app/version/new/url/get?appType=" + (this.isIOS ? 2 : 1)
      );
      if (res.data.code === "00000") {
        downloadUrl = res.data.data;
        if (downloadUrl) {
          window.location.href = downloadUrl;
        } else {
          Dialog({ message: "下载地址不存在" });
        }
      } else {
        Dialog({ message: "网络错误，请重试" });
      }
    },
  },
  mounted() {
    window.document.title = "国创优选";
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

div,
button,
h1,
p {
  margin: 0;
  box-sizing: border-box;
}

button {
  border: none;
  outline: none;
}

h1 {
  font-weight: normal;
}

.wrapper {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 30px;
}

.face-a {
  background-color: #c0042a;
  background-image: url("../assets/back.jpg");
  padding-top: 35px;
  text-align: center;
  color: #fff;
}

.face-b {
  background-image: url("../assets/back2.jpg");
}

.top-logo {
  width: 55px;
  padding-bottom: 40px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.mid-logo {
  height: 95px;
  display: block;
  margin: 0 auto;
}

.slogan {
  padding: 10px;
  line-height: 1.5;
  font-size: 18px;
}

.download-btn {
  color: #fff;
  width: 150px;
  height: 45px;
  border: 1px solid currentColor;
  line-height: 45px;
  font-size: 18px;
  background: transparent;
  border-radius: 22.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 100px;
}

.download-logo {
  width: 20px;
  padding-left: 0.5em;
  transform: translateY(-2px);
}
</style>
